import axios from '../utils/axios';

import {fetchAccountUserprofileListStart, fetchAccountUserprofileListSuccess, fetchAccountUserprofileListFailure} from '../features/account'
import {fetchAccountUserprofileItemStart, fetchAccountUserprofileItemSuccess, fetchAccountUserprofileItemFailure} from '../features/account'
import {createAccountUserprofileItemStart, createAccountUserprofileItemSuccess, createAccountUserprofileItemFailure} from '../features/account'
import {updateAccountUserprofileItemStart, updateAccountUserprofileItemSuccess, updateAccountUserprofileItemFailure} from '../features/account'
import {deleteAccountUserprofileItemStart, deleteAccountUserprofileItemSuccess, deleteAccountUserprofileItemFailure} from '../features/account'

import {fetchAccountUsermessagedispatchListStart, fetchAccountUsermessagedispatchListSuccess, fetchAccountUsermessagedispatchListFailure} from '../features/account'
import {fetchAccountUsermessagedispatchItemStart, fetchAccountUsermessagedispatchItemSuccess, fetchAccountUsermessagedispatchItemFailure} from '../features/account'
import {createAccountUsermessagedispatchItemStart, createAccountUsermessagedispatchItemSuccess, createAccountUsermessagedispatchItemFailure} from '../features/account'
import {updateAccountUsermessagedispatchItemStart, updateAccountUsermessagedispatchItemSuccess, updateAccountUsermessagedispatchItemFailure} from '../features/account'
import {deleteAccountUsermessagedispatchItemStart, deleteAccountUsermessagedispatchItemSuccess, deleteAccountUsermessagedispatchItemFailure} from '../features/account'

import {fetchAccountTenantchecklistListStart, fetchAccountTenantchecklistListSuccess, fetchAccountTenantchecklistListFailure} from '../features/account'
import {fetchAccountTenantchecklistItemStart, fetchAccountTenantchecklistItemSuccess, fetchAccountTenantchecklistItemFailure} from '../features/account'
import {createAccountTenantchecklistItemStart, createAccountTenantchecklistItemSuccess, createAccountTenantchecklistItemFailure} from '../features/account'
import {updateAccountTenantchecklistItemStart, updateAccountTenantchecklistItemSuccess, updateAccountTenantchecklistItemFailure} from '../features/account'
import {deleteAccountTenantchecklistItemStart, deleteAccountTenantchecklistItemSuccess, deleteAccountTenantchecklistItemFailure} from '../features/account'

import {fetchAccountTenantpaymentListStart, fetchAccountTenantpaymentListSuccess, fetchAccountTenantpaymentListFailure} from '../features/account'
import {fetchAccountTenantpaymentItemStart, fetchAccountTenantpaymentItemSuccess, fetchAccountTenantpaymentItemFailure} from '../features/account'
import {createAccountTenantpaymentItemStart, createAccountTenantpaymentItemSuccess, createAccountTenantpaymentItemFailure} from '../features/account'
import {updateAccountTenantpaymentItemStart, updateAccountTenantpaymentItemSuccess, updateAccountTenantpaymentItemFailure} from '../features/account'
import {deleteAccountTenantpaymentItemStart, deleteAccountTenantpaymentItemSuccess, deleteAccountTenantpaymentItemFailure} from '../features/account'

import {fetchAccountTenantadminListStart, fetchAccountTenantadminListSuccess, fetchAccountTenantadminListFailure} from '../features/account'
import {fetchAccountTenantadminItemStart, fetchAccountTenantadminItemSuccess, fetchAccountTenantadminItemFailure} from '../features/account'
import {createAccountTenantadminItemStart, createAccountTenantadminItemSuccess, createAccountTenantadminItemFailure} from '../features/account'
import {updateAccountTenantadminItemStart, updateAccountTenantadminItemSuccess, updateAccountTenantadminItemFailure} from '../features/account'
import {deleteAccountTenantadminItemStart, deleteAccountTenantadminItemSuccess, deleteAccountTenantadminItemFailure} from '../features/account'


// USERPROFILE ACTIONS
export const fetchAccountUserprofileList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountUserprofileListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileListFailure({key, error: error.message}));
    }
}
export const fetchAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const createAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/userprofile/`, info.payload, { headers: info.headers });
        dispatch(createAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const updateAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountUserprofileItemFailure({key, error: error.message}));
    }
}
export const deleteAccountUserprofileItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountUserprofileItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/userprofile/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountUserprofileItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountUserprofileItemFailure({key, error: error.message}));
    }
}


// USERMESSAGEDISPATCH ACTIONS
export const fetchAccountUsermessagedispatchList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUsermessagedispatchListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/usermessagedispatch/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountUsermessagedispatchListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUsermessagedispatchListFailure({key, error: error.message}));
    }
}
export const fetchAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const createAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/usermessagedispatch/`, info.payload, { headers: info.headers });
        dispatch(createAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const updateAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}
export const deleteAccountUsermessagedispatchItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountUsermessagedispatchItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/usermessagedispatch/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountUsermessagedispatchItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountUsermessagedispatchItemFailure({key, error: error.message}));
    }
}


// TENANTCHECKLIST ACTIONS
export const fetchAccountTenantchecklistList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantchecklistListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantchecklist/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountTenantchecklistListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantchecklistListFailure({key, error: error.message}));
    }
}
export const fetchAccountTenantchecklistItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantchecklistItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantchecklist/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountTenantchecklistItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantchecklistItemFailure({key, error: error.message}));
    }
}
export const createAccountTenantchecklistItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountTenantchecklistItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/tenantchecklist/`, info.payload, { headers: info.headers });
        dispatch(createAccountTenantchecklistItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountTenantchecklistItemFailure({key, error: error.message}));
    }
}
export const updateAccountTenantchecklistItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountTenantchecklistItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/tenantchecklist/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountTenantchecklistItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountTenantchecklistItemFailure({key, error: error.message}));
    }
}
export const deleteAccountTenantchecklistItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountTenantchecklistItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/tenantchecklist/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountTenantchecklistItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountTenantchecklistItemFailure({key, error: error.message}));
    }
}


// TENANTPAYMENT ACTIONS
export const fetchAccountTenantpaymentList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantpaymentListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantpayment/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountTenantpaymentListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantpaymentListFailure({key, error: error.message}));
    }
}
export const fetchAccountTenantpaymentItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantpaymentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantpayment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountTenantpaymentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantpaymentItemFailure({key, error: error.message}));
    }
}
export const createAccountTenantpaymentItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountTenantpaymentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/tenantpayment/`, info.payload, { headers: info.headers });
        dispatch(createAccountTenantpaymentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountTenantpaymentItemFailure({key, error: error.message}));
    }
}
export const updateAccountTenantpaymentItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountTenantpaymentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/tenantpayment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountTenantpaymentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountTenantpaymentItemFailure({key, error: error.message}));
    }
}
export const deleteAccountTenantpaymentItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountTenantpaymentItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/tenantpayment/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountTenantpaymentItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountTenantpaymentItemFailure({key, error: error.message}));
    }
}


// TENANTADMIN ACTIONS
export const fetchAccountTenantadminList = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantadminListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantadmin/`, { params: info.params, headers: info.headers });
        dispatch(fetchAccountTenantadminListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantadminListFailure({key, error: error.message}));
    }
}
export const fetchAccountTenantadminItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchAccountTenantadminItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/account/tenantadmin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchAccountTenantadminItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchAccountTenantadminItemFailure({key, error: error.message}));
    }
}
export const createAccountTenantadminItem = async (dispatch, info, key) => {
    try{
        dispatch(createAccountTenantadminItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/account/tenantadmin/`, info.payload, { headers: info.headers });
        dispatch(createAccountTenantadminItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createAccountTenantadminItemFailure({key, error: error.message}));
    }
}
export const updateAccountTenantadminItem = async (dispatch, info, key) => {
    try{
        dispatch(updateAccountTenantadminItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/account/tenantadmin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateAccountTenantadminItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateAccountTenantadminItemFailure({key, error: error.message}));
    }
}
export const deleteAccountTenantadminItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteAccountTenantadminItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/account/tenantadmin/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteAccountTenantadminItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteAccountTenantadminItemFailure({key, error: error.message}));
    }
}




