import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'payment',
  initialState: { loading: false },
  reducers: {
    
    // PAYMENTMETHOD REDUCER FUNCTION

    // PAYMENTMETHOD FETCH LIST
    fetchPaymentPaymentmethodListStart: stateLoadingStart,
    fetchPaymentPaymentmethodListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_list`),
    fetchPaymentPaymentmethodListFailure: stateError,

    // PAYMENTMETHOD FETCH ITEM
    fetchPaymentPaymentmethodItemStart: stateLoadingStart,
    fetchPaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    fetchPaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD CREATE ITEM
    createPaymentPaymentmethodItemStart: stateLoadingStart,
    createPaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    createPaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD UPDATE ITEM
    updatePaymentPaymentmethodItemStart: stateLoadingStart,
    updatePaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    updatePaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD DELETE ITEM
    deletePaymentPaymentmethodItemStart: stateLoadingStart,
    deletePaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_paymentmethod`),
    deletePaymentPaymentmethodItemFailure: stateError,


    // PAYMENTTRANSACTION REDUCER FUNCTION

    // PAYMENTTRANSACTION FETCH LIST
    fetchPaymentPaymenttransactionListStart: stateLoadingStart,
    fetchPaymentPaymenttransactionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_list`),
    fetchPaymentPaymenttransactionListFailure: stateError,

    // PAYMENTTRANSACTION FETCH ITEM
    fetchPaymentPaymenttransactionItemStart: stateLoadingStart,
    fetchPaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    fetchPaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION CREATE ITEM
    createPaymentPaymenttransactionItemStart: stateLoadingStart,
    createPaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    createPaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION UPDATE ITEM
    updatePaymentPaymenttransactionItemStart: stateLoadingStart,
    updatePaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    updatePaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION DELETE ITEM
    deletePaymentPaymenttransactionItemStart: stateLoadingStart,
    deletePaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_paymenttransaction`),
    deletePaymentPaymenttransactionItemFailure: stateError,


    // TENANTBILLINFORMATION REDUCER FUNCTION

    // TENANTBILLINFORMATION FETCH LIST
    fetchPaymentTenantbillinformationListStart: stateLoadingStart,
    fetchPaymentTenantbillinformationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantbillinformation_list`),
    fetchPaymentTenantbillinformationListFailure: stateError,

    // TENANTBILLINFORMATION FETCH ITEM
    fetchPaymentTenantbillinformationItemStart: stateLoadingStart,
    fetchPaymentTenantbillinformationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantbillinformation_item`),
    fetchPaymentTenantbillinformationItemFailure: stateError,

    // TENANTBILLINFORMATION CREATE ITEM
    createPaymentTenantbillinformationItemStart: stateLoadingStart,
    createPaymentTenantbillinformationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantbillinformation_item`),
    createPaymentTenantbillinformationItemFailure: stateError,

    // TENANTBILLINFORMATION UPDATE ITEM
    updatePaymentTenantbillinformationItemStart: stateLoadingStart,
    updatePaymentTenantbillinformationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantbillinformation_item`),
    updatePaymentTenantbillinformationItemFailure: stateError,

    // TENANTBILLINFORMATION DELETE ITEM
    deletePaymentTenantbillinformationItemStart: stateLoadingStart,
    deletePaymentTenantbillinformationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenantbillinformation`),
    deletePaymentTenantbillinformationItemFailure: stateError,


  },
});

export const {
  fetchPaymentPaymentmethodListStart, fetchPaymentPaymenttransactionListStart, fetchPaymentTenantbillinformationListStart,
  fetchPaymentPaymentmethodListSuccess, fetchPaymentPaymenttransactionListSuccess, fetchPaymentTenantbillinformationListSuccess,
  fetchPaymentPaymentmethodListFailure, fetchPaymentPaymenttransactionListFailure, fetchPaymentTenantbillinformationListFailure,
  fetchPaymentPaymentmethodItemStart, fetchPaymentPaymenttransactionItemStart, fetchPaymentTenantbillinformationItemStart,
  fetchPaymentPaymentmethodItemSuccess, fetchPaymentPaymenttransactionItemSuccess, fetchPaymentTenantbillinformationItemSuccess,
  fetchPaymentPaymentmethodItemFailure, fetchPaymentPaymenttransactionItemFailure, fetchPaymentTenantbillinformationItemFailure,
  createPaymentPaymentmethodItemStart, createPaymentPaymenttransactionItemStart, createPaymentTenantbillinformationItemStart,
  createPaymentPaymentmethodItemSuccess, createPaymentPaymenttransactionItemSuccess, createPaymentTenantbillinformationItemSuccess,
  createPaymentPaymentmethodItemFailure, createPaymentPaymenttransactionItemFailure, createPaymentTenantbillinformationItemFailure,
  updatePaymentPaymentmethodItemStart, updatePaymentPaymenttransactionItemStart, updatePaymentTenantbillinformationItemStart,
  updatePaymentPaymentmethodItemSuccess, updatePaymentPaymenttransactionItemSuccess, updatePaymentTenantbillinformationItemSuccess,
  updatePaymentPaymentmethodItemFailure, updatePaymentPaymenttransactionItemFailure, updatePaymentTenantbillinformationItemFailure,
  deletePaymentPaymentmethodItemStart, deletePaymentPaymenttransactionItemStart, deletePaymentTenantbillinformationItemStart,
  deletePaymentPaymentmethodItemSuccess, deletePaymentPaymenttransactionItemSuccess, deletePaymentTenantbillinformationItemSuccess,
  deletePaymentPaymentmethodItemFailure, deletePaymentPaymenttransactionItemFailure, deletePaymentTenantbillinformationItemFailure
} = slice.actions;

export default slice.reducer;
