import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'tenant',
  initialState: { loading: false },
  reducers: {
    
    // TENANT REDUCER FUNCTION

    // TENANT FETCH LIST
    fetchTenantTenantListStart: stateLoadingStart,
    fetchTenantTenantListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenant_list`),
    fetchTenantTenantListFailure: stateError,

    // TENANT FETCH ITEM
    fetchTenantTenantItemStart: stateLoadingStart,
    fetchTenantTenantItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenant_item`),
    fetchTenantTenantItemFailure: stateError,

    // TENANT CREATE ITEM
    createTenantTenantItemStart: stateLoadingStart,
    createTenantTenantItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenant_item`),
    createTenantTenantItemFailure: stateError,

    // TENANT UPDATE ITEM
    updateTenantTenantItemStart: stateLoadingStart,
    updateTenantTenantItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenant_item`),
    updateTenantTenantItemFailure: stateError,

    // TENANT DELETE ITEM
    deleteTenantTenantItemStart: stateLoadingStart,
    deleteTenantTenantItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenant`),
    deleteTenantTenantItemFailure: stateError,


    // TENANTANALYTICS REDUCER FUNCTION

    // TENANTANALYTICS FETCH LIST
    fetchTenantTenantanalyticsListStart: stateLoadingStart,
    fetchTenantTenantanalyticsListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantanalytics_list`),
    fetchTenantTenantanalyticsListFailure: stateError,

    // TENANTANALYTICS FETCH ITEM
    fetchTenantTenantanalyticsItemStart: stateLoadingStart,
    fetchTenantTenantanalyticsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantanalytics_item`),
    fetchTenantTenantanalyticsItemFailure: stateError,

    // TENANTANALYTICS CREATE ITEM
    createTenantTenantanalyticsItemStart: stateLoadingStart,
    createTenantTenantanalyticsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantanalytics_item`),
    createTenantTenantanalyticsItemFailure: stateError,

    // TENANTANALYTICS UPDATE ITEM
    updateTenantTenantanalyticsItemStart: stateLoadingStart,
    updateTenantTenantanalyticsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantanalytics_item`),
    updateTenantTenantanalyticsItemFailure: stateError,

    // TENANTANALYTICS DELETE ITEM
    deleteTenantTenantanalyticsItemStart: stateLoadingStart,
    deleteTenantTenantanalyticsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenantanalytics`),
    deleteTenantTenantanalyticsItemFailure: stateError,


  },
});

export const {
  fetchTenantTenantListStart, fetchTenantTenantanalyticsListStart,
  fetchTenantTenantListSuccess, fetchTenantTenantanalyticsListSuccess,
  fetchTenantTenantListFailure, fetchTenantTenantanalyticsListFailure,
  fetchTenantTenantItemStart, fetchTenantTenantanalyticsItemStart,
  fetchTenantTenantItemSuccess, fetchTenantTenantanalyticsItemSuccess,
  fetchTenantTenantItemFailure, fetchTenantTenantanalyticsItemFailure,
  createTenantTenantItemStart, createTenantTenantanalyticsItemStart,
  createTenantTenantItemSuccess, createTenantTenantanalyticsItemSuccess,
  createTenantTenantItemFailure, createTenantTenantanalyticsItemFailure,
  updateTenantTenantItemStart, updateTenantTenantanalyticsItemStart,
  updateTenantTenantItemSuccess, updateTenantTenantanalyticsItemSuccess,
  updateTenantTenantItemFailure, updateTenantTenantanalyticsItemFailure,
  deleteTenantTenantItemStart, deleteTenantTenantanalyticsItemStart,
  deleteTenantTenantItemSuccess, deleteTenantTenantanalyticsItemSuccess,
  deleteTenantTenantItemFailure, deleteTenantTenantanalyticsItemFailure
} = slice.actions;

export default slice.reducer;
