import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'product',
  initialState: { loading: false },
  reducers: {
    
    // CATEGORY REDUCER FUNCTION

    // CATEGORY FETCH LIST
    fetchProductCategoryListStart: stateLoadingStart,
    fetchProductCategoryListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_list`),
    fetchProductCategoryListFailure: stateError,

    // CATEGORY FETCH ITEM
    fetchProductCategoryItemStart: stateLoadingStart,
    fetchProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    fetchProductCategoryItemFailure: stateError,

    // CATEGORY CREATE ITEM
    createProductCategoryItemStart: stateLoadingStart,
    createProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    createProductCategoryItemFailure: stateError,

    // CATEGORY UPDATE ITEM
    updateProductCategoryItemStart: stateLoadingStart,
    updateProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    updateProductCategoryItemFailure: stateError,

    // CATEGORY DELETE ITEM
    deleteProductCategoryItemStart: stateLoadingStart,
    deleteProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_category`),
    deleteProductCategoryItemFailure: stateError,


    // PRODUCT REDUCER FUNCTION

    // PRODUCT FETCH LIST
    fetchProductProductListStart: stateLoadingStart,
    fetchProductProductListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_list`),
    fetchProductProductListFailure: stateError,

    // PRODUCT FETCH ITEM
    fetchProductProductItemStart: stateLoadingStart,
    fetchProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    fetchProductProductItemFailure: stateError,

    // PRODUCT CREATE ITEM
    createProductProductItemStart: stateLoadingStart,
    createProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    createProductProductItemFailure: stateError,

    // PRODUCT UPDATE ITEM
    updateProductProductItemStart: stateLoadingStart,
    updateProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    updateProductProductItemFailure: stateError,

    // PRODUCT DELETE ITEM
    deleteProductProductItemStart: stateLoadingStart,
    deleteProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_product`),
    deleteProductProductItemFailure: stateError,


    // HOWTOUSE REDUCER FUNCTION

    // HOWTOUSE FETCH LIST
    fetchProductHowtouseListStart: stateLoadingStart,
    fetchProductHowtouseListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `howtouse_list`),
    fetchProductHowtouseListFailure: stateError,

    // HOWTOUSE FETCH ITEM
    fetchProductHowtouseItemStart: stateLoadingStart,
    fetchProductHowtouseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `howtouse_item`),
    fetchProductHowtouseItemFailure: stateError,

    // HOWTOUSE CREATE ITEM
    createProductHowtouseItemStart: stateLoadingStart,
    createProductHowtouseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `howtouse_item`),
    createProductHowtouseItemFailure: stateError,

    // HOWTOUSE UPDATE ITEM
    updateProductHowtouseItemStart: stateLoadingStart,
    updateProductHowtouseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `howtouse_item`),
    updateProductHowtouseItemFailure: stateError,

    // HOWTOUSE DELETE ITEM
    deleteProductHowtouseItemStart: stateLoadingStart,
    deleteProductHowtouseItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_howtouse`),
    deleteProductHowtouseItemFailure: stateError,


    // INGREDIENT REDUCER FUNCTION

    // INGREDIENT FETCH LIST
    fetchProductIngredientListStart: stateLoadingStart,
    fetchProductIngredientListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ingredient_list`),
    fetchProductIngredientListFailure: stateError,

    // INGREDIENT FETCH ITEM
    fetchProductIngredientItemStart: stateLoadingStart,
    fetchProductIngredientItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ingredient_item`),
    fetchProductIngredientItemFailure: stateError,

    // INGREDIENT CREATE ITEM
    createProductIngredientItemStart: stateLoadingStart,
    createProductIngredientItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ingredient_item`),
    createProductIngredientItemFailure: stateError,

    // INGREDIENT UPDATE ITEM
    updateProductIngredientItemStart: stateLoadingStart,
    updateProductIngredientItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `ingredient_item`),
    updateProductIngredientItemFailure: stateError,

    // INGREDIENT DELETE ITEM
    deleteProductIngredientItemStart: stateLoadingStart,
    deleteProductIngredientItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_ingredient`),
    deleteProductIngredientItemFailure: stateError,


    // CLINICALTEST REDUCER FUNCTION

    // CLINICALTEST FETCH LIST
    fetchProductClinicaltestListStart: stateLoadingStart,
    fetchProductClinicaltestListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `clinicaltest_list`),
    fetchProductClinicaltestListFailure: stateError,

    // CLINICALTEST FETCH ITEM
    fetchProductClinicaltestItemStart: stateLoadingStart,
    fetchProductClinicaltestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `clinicaltest_item`),
    fetchProductClinicaltestItemFailure: stateError,

    // CLINICALTEST CREATE ITEM
    createProductClinicaltestItemStart: stateLoadingStart,
    createProductClinicaltestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `clinicaltest_item`),
    createProductClinicaltestItemFailure: stateError,

    // CLINICALTEST UPDATE ITEM
    updateProductClinicaltestItemStart: stateLoadingStart,
    updateProductClinicaltestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `clinicaltest_item`),
    updateProductClinicaltestItemFailure: stateError,

    // CLINICALTEST DELETE ITEM
    deleteProductClinicaltestItemStart: stateLoadingStart,
    deleteProductClinicaltestItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_clinicaltest`),
    deleteProductClinicaltestItemFailure: stateError,


    // PRODUCTDETAIL REDUCER FUNCTION

    // PRODUCTDETAIL FETCH LIST
    fetchProductProductdetailListStart: stateLoadingStart,
    fetchProductProductdetailListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productdetail_list`),
    fetchProductProductdetailListFailure: stateError,

    // PRODUCTDETAIL FETCH ITEM
    fetchProductProductdetailItemStart: stateLoadingStart,
    fetchProductProductdetailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productdetail_item`),
    fetchProductProductdetailItemFailure: stateError,

    // PRODUCTDETAIL CREATE ITEM
    createProductProductdetailItemStart: stateLoadingStart,
    createProductProductdetailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productdetail_item`),
    createProductProductdetailItemFailure: stateError,

    // PRODUCTDETAIL UPDATE ITEM
    updateProductProductdetailItemStart: stateLoadingStart,
    updateProductProductdetailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `productdetail_item`),
    updateProductProductdetailItemFailure: stateError,

    // PRODUCTDETAIL DELETE ITEM
    deleteProductProductdetailItemStart: stateLoadingStart,
    deleteProductProductdetailItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_productdetail`),
    deleteProductProductdetailItemFailure: stateError,


  },
});

export const {
  fetchProductCategoryListStart, fetchProductProductListStart, fetchProductHowtouseListStart, fetchProductIngredientListStart, fetchProductClinicaltestListStart, fetchProductProductdetailListStart,
  fetchProductCategoryListSuccess, fetchProductProductListSuccess, fetchProductHowtouseListSuccess, fetchProductIngredientListSuccess, fetchProductClinicaltestListSuccess, fetchProductProductdetailListSuccess,
  fetchProductCategoryListFailure, fetchProductProductListFailure, fetchProductHowtouseListFailure, fetchProductIngredientListFailure, fetchProductClinicaltestListFailure, fetchProductProductdetailListFailure,
  fetchProductCategoryItemStart, fetchProductProductItemStart, fetchProductHowtouseItemStart, fetchProductIngredientItemStart, fetchProductClinicaltestItemStart, fetchProductProductdetailItemStart,
  fetchProductCategoryItemSuccess, fetchProductProductItemSuccess, fetchProductHowtouseItemSuccess, fetchProductIngredientItemSuccess, fetchProductClinicaltestItemSuccess, fetchProductProductdetailItemSuccess,
  fetchProductCategoryItemFailure, fetchProductProductItemFailure, fetchProductHowtouseItemFailure, fetchProductIngredientItemFailure, fetchProductClinicaltestItemFailure, fetchProductProductdetailItemFailure,
  createProductCategoryItemStart, createProductProductItemStart, createProductHowtouseItemStart, createProductIngredientItemStart, createProductClinicaltestItemStart, createProductProductdetailItemStart,
  createProductCategoryItemSuccess, createProductProductItemSuccess, createProductHowtouseItemSuccess, createProductIngredientItemSuccess, createProductClinicaltestItemSuccess, createProductProductdetailItemSuccess,
  createProductCategoryItemFailure, createProductProductItemFailure, createProductHowtouseItemFailure, createProductIngredientItemFailure, createProductClinicaltestItemFailure, createProductProductdetailItemFailure,
  updateProductCategoryItemStart, updateProductProductItemStart, updateProductHowtouseItemStart, updateProductIngredientItemStart, updateProductClinicaltestItemStart, updateProductProductdetailItemStart,
  updateProductCategoryItemSuccess, updateProductProductItemSuccess, updateProductHowtouseItemSuccess, updateProductIngredientItemSuccess, updateProductClinicaltestItemSuccess, updateProductProductdetailItemSuccess,
  updateProductCategoryItemFailure, updateProductProductItemFailure, updateProductHowtouseItemFailure, updateProductIngredientItemFailure, updateProductClinicaltestItemFailure, updateProductProductdetailItemFailure,
  deleteProductCategoryItemStart, deleteProductProductItemStart, deleteProductHowtouseItemStart, deleteProductIngredientItemStart, deleteProductClinicaltestItemStart, deleteProductProductdetailItemStart,
  deleteProductCategoryItemSuccess, deleteProductProductItemSuccess, deleteProductHowtouseItemSuccess, deleteProductIngredientItemSuccess, deleteProductClinicaltestItemSuccess, deleteProductProductdetailItemSuccess,
  deleteProductCategoryItemFailure, deleteProductProductItemFailure, deleteProductHowtouseItemFailure, deleteProductIngredientItemFailure, deleteProductClinicaltestItemFailure, deleteProductProductdetailItemFailure
} = slice.actions;

export default slice.reducer;
