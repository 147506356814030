import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Dropdown,
  Confirm,
  Grid,
  Pagination,
  Loader,
  Icon,
  Image,
  Statistic,
  Checkbox,
  TextArea
} from "semantic-ui-react";
import { debounce } from "lodash";
import {
  configureProductProduct,
  configureProductCategory,
  PAGE_LIMIT,
} from "services/access/product";

import { utils } from "services/api-data";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProductDetails from "pages/ProductDetail";
import ProductDetailComponent from "./productDetail";
import { compressImage } from "./categories";

const ProductsPage = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [products, setProducts] = useState();
  const [toastMessage, setToastMessage] = useState("");
  const [triggerAction, setTriggerAction] = useState(false);
  const itemsPerpage = PAGE_LIMIT;
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureProductProduct(
    utils,
    dispatch,
    state
  );
  const category_config = configureProductCategory(utils, dispatch, state);

  useEffect(() => {
    initialize();
    category_config.action.fetchCategoryList();
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    fetchMore(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setLoading(data?.product[`product_page_${currentPage}_loading`]);
    setError(data?.product[`product_page_${currentPage}_error`]);
  }, [currentPage, data?.product]);

  const fetchMore = (page) => {
    action.getPages(page);
  };

  // Dummy categories data

  useEffect(() => {
    if (category_config?.data?.category_fetch_list) {
      setCategories(category_config?.data?.category_fetch_list);
    }
  }, [category_config?.data?.category_fetch_list]);

  const categories_option = category_config?.data?.category_fetch_list?.map(
    (i) => ({
      key: i.id,
      text: i.title,
      value: i.id,
    })
  );

  // Dummy products data

  useEffect(() => {
    const pageCount = data?.product[`product_page_${currentPage}`]?.count || 0;
    setTotalPages(Math.ceil(pageCount / itemsPerpage));
    setProducts(data?.product[`product_page_${currentPage}`]?.results);
  }, [data?.product, currentPage]);

  // Get current products for the current page

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };
  // State for modal and new product
  const [open, setOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    offered_price: "",
    stock_quantity: "",
    min_order_quantity: "",
    is_pinned: false,
    image: undefined,
    category: undefined,
    how_to_use: "",
    ingredients: "",
    clinical_tests: "",
    product_details: "",
  });

  // State for confirmation dialog
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  // State for edit modal
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  //State for view modal
  const [viewModalOpen, setViewModal] = useState(false);

  const handleAddProduct = async () => {
    await action.createProductItem({
      name: newProduct.name,
      description: newProduct.description,
      offered_price: newProduct.offered_price,
      price: newProduct.price,
      stock_quantity: newProduct.stock_quantity,
      min_order_quantity: newProduct.min_order_quantity,
      is_pinned: newProduct.is_pinned,
      image: newProduct.image,
      category: newProduct.category,
      how_to_use: newProduct.how_to_use,
      ingredients: newProduct.ingredients,
      clinical_tests: newProduct.clinical_tests,
      product_details: newProduct.product_details,
    });
    setToastMessage("Product added sucessfully");
    setTriggerAction(true);
    setNewProduct({
      name: "",
      description: "",
      price: "",
      stock_quantity: "",
      min_order_quantity: "",
      is_pinned: false,
      image: undefined,
      category: undefined,
      how_to_use: "",
      ingredients: "",
      clinical_tests: "",
      product_details: "",
    });

    setOpen(false);
  };

  // Function to handle file upload
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    try {
      // Compress the image
      const compressedImage = await compressImage(file);
      // Update the appropriate state with the compressed image
      if (editModalOpen) {
        setSelectedProduct({ ...selectedProduct, image: compressedImage });
      } else {
        setNewProduct({ ...newProduct, image: compressedImage });
      }
    } catch (error) {
      // Handle any errors that occur during compression
      console.error("Error compressing image:", error);
      // Optionally set error states or show error messages to the user
    }
  };

  // Function to open the confirmation dialog before deleting a product
  const handleOpenConfirm = (id) => {
    const product = products.find((product) => product.id === id);
    setSelectedProduct(product);
    setDeleteProductId(id);
    setConfirmOpen(true);
  };

  // Function to handle canceling the deletion
  const handleCancelDelete = () => {
    setConfirmOpen(false);
    setDeleteProductId(null);
    setSelectedProduct(null);
  };

  // Function to handle confirming and deleting a product
  const handleConfirmDelete = async () => {
    await action.deleteProductItem(deleteProductId);
    setToastMessage("Product removed sucessfully");
    setTriggerAction(true);
    setConfirmOpen(false);
    setDeleteProductId(null);
    setSelectedProduct(null);
  };

  // Function to open the edit modal
  const handleOpenEditModal = (id) => {
    const product = products.find((product) => product.id === id);
    setSelectedProduct(product);
    setEditModalOpen(true);
  };

  // Function to handle editing a product
  const handleEditProduct = async (id) => {
    await action.updateProductItem(selectedProduct.id, selectedProduct);
    setToastMessage("Product edited sucessfully");
    setTriggerAction(true);
    setEditModalOpen(false);
    setSelectedProduct(null);
  };

  const handleToggleChange = async (product) => {
    const updatedProduct = {
      ...product,
      is_pinned: !product.is_pinned,
    };
    await action.updateProductItem(product.id, updatedProduct);
    setProducts((prevProducts) =>
      prevProducts.map((p) => (p.id === product.id ? updatedProduct : p))
    );
    setTriggerAction(true);
  };

  useEffect(() => {
    if (triggerAction) {
      if (!loading) {
        if (error) {
          toast.error("Error occured");
          setTriggerAction(false);
        } else {
          if (toastMessage) {
            toast.success(toastMessage);
          }
          fetchMore(currentPage);
          setTriggerAction(false);
        }
      }
    }
  }, [loading, triggerAction]);

  const [searchTerm, setSearchTerm] = useState("");
  const [activeField, setActiveField] = useState();
  const [searchEvent, setSearchEvent] = useState(false);
  const debouncedSearch = debounce((searchTerm) => {
    setSearchEvent(true);
  }, 1500);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };
  useEffect(() => {
    if (searchEvent) {
      action.fetchProductListBySearch(searchTerm);
      setSearchEvent(false);
    }
  }, [searchEvent, searchTerm]);

  useEffect(() => {
    if (data?.product_fetch_list) {
      setProducts(data?.product_fetch_list);
    }
  }, [data?.product_fetch_list]);

  return (
    <Container style={{ marginTop: "50px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Header as="h2">Products</Header>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", width: "60%" }}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Search products..."
              style={{ marginRight: "10px", width: "100%" }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {searchTerm && (
              <Icon
                name="close"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSearchTerm("");
                  setSearchEvent(true);
                }}
              />
            )}
          </div>
          <Button
            style={{ marginLeft: "10px" }}
            primary
            onClick={() => setOpen(true)}
          >
            Add Product
          </Button>
        </div>
      </div>

      <>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Offered Price</Table.HeaderCell>
              <Table.HeaderCell> Stock quantity </Table.HeaderCell>

              <Table.HeaderCell> Pin </Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {loading ? (
              <Loader active inline />
            ) : products?.length > 0 ? (
              products?.map((product) => (
                <Table.Row
                  key={product.id}
                  textAlign="center"
                  style={{
                    backgroundColor:
                      product?.stock_quantity === 0 ? "#ffcccc" : "",
                  }}
                >
                  <Table.Cell>{product.name}</Table.Cell>
                  <Table.Cell>{product.description}</Table.Cell>
                  <Table.Cell>
                    {product?.category &&
                      categories?.find((cat) => cat.id === product.category)
                        ?.title}
                  </Table.Cell>
                  <Table.Cell>{product.price}</Table.Cell>
                  <Table.Cell>{(product.offered_price > 0) ? product.offered_price : 'N/A'}</Table.Cell>

                  <Table.Cell> {product.stock_quantity} </Table.Cell>
                  <Table.Cell>
                    {" "}
                    <Checkbox
                      toggle
                      checked={product.is_pinned}
                      onChange={() => handleToggleChange(product)}
                    />{" "}
                  </Table.Cell>
                  <Table.Cell>
                    <img
                      src={product.image}
                      alt={product.name}
                      style={{ width: "100px", height: "auto" }}
                    />
                  </Table.Cell>

                  <Table.Cell singleLine>
                    <Button
                      icon
                      secondary
                      basic
                      onClick={() => {
                        setSelectedProduct(product);
                        setViewModal(true);
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      basic
                      primary
                      icon
                      onClick={() => handleOpenEditModal(product.id)}
                    >
                      <Icon name="edit alternate" />
                    </Button>
                    <Button
                      icon
                      basic
                      negative
                      onClick={() => handleOpenConfirm(product.id)}
                    >
                      <Icon name="trash alternate" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={7}>
                  {" "}
                  <h3> No Products Found </h3>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Grid centered>
          <Pagination
            pointing
            secondary
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePaginationChange}
          />
        </Grid>
      </>

      {/* Modal for adding a new product */}
      <Modal size="tiny" open={open} onClose={() => setOpen(false)} closeIcon>
        <Modal.Header>Add New Product</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input
                placeholder="Product name"
                value={newProduct.name}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, name: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea
                placeholder="Product description"
                value={newProduct.description}
                onChange={(e) =>{
                  setNewProduct({ ...newProduct, description: e.target.value })
                  console.log(e.target.value);}
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Price</label>
              <Input
                placeholder="Product price"
                value={((newProduct.offered_price > 0) ? newProduct.offered_price : newProduct.price)}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, price: e.target.value })
                }
              />
            </Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label> Stock quantity </label>
                <Input
                  placeholder="Stock Quantity"
                  value={newProduct.stock_quantity}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      stock_quantity: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label> Minimum Order quantity </label>
                <Input
                  placeholder="Minimum product order"
                  value={newProduct.min_order_quantity}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      min_order_quantity: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Checkbox
                label="Is Pinned"
                style={{ paddingTop: 5 }}
                checked={newProduct.is_pinned}
                onChange={(e, data) =>
                  setNewProduct({ ...newProduct, is_pinned: data.checked })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Dropdown
                placeholder="Select category"
                fluid
                selection
                options={categories_option}
                value={newProduct.category}
                onChange={(e, data) =>
                  setNewProduct({ ...newProduct, category: data.value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Image</label>
              <Input type="file" accept="image/*" onChange={handleFileUpload} />
            </Form.Field>





            <Form.Field>
              <label>How To Use</label>
              <TextArea
                placeholder="How To Use"
                value={newProduct.how_to_use}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, how_to_use: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Ingredients</label>
              <TextArea
                placeholder="Ingredients"
                value={newProduct.ingredients}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, ingredients: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Clinical Tests</label>
              <TextArea
                placeholder="Clinical Tests"
                value={newProduct.clinical_tests}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, clinical_tests: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Product Details</label>
              <TextArea
                placeholder="Product Details"
                value={newProduct.product_details}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, product_details: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>


          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleAddProduct}>
            Add Product
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Confirmation dialog for deleting a product */}
      <Confirm
        open={confirmOpen}
        header={"Delete this product"}
        content={
          <div style={{ padding: "25px" }}>
            Are you sure you want to delete this product{" "}
            <b>{selectedProduct?.name}</b>?
          </div>
        }
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      {/* Edit modal */}
      <Modal
        size="tiny"
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeIcon
      >
        <Modal.Header>Edit Product</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <Input
                value={selectedProduct ? selectedProduct.name : ""}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    name: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Input
                value={selectedProduct ? selectedProduct.description : ""}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    description: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Price</label>
              <Input
                value={selectedProduct ? (selectedProduct.price) : ""}
                onChange={(e) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    price: e.target.value,
                  })
                }
              />
            </Form.Field>
            <Form.Field>

              <label>
                <input
                  type="checkbox"
                  checked={selectedProduct?.offered_price>0 || activeField === 'offered_price'}
                  onChange={e=>(
                    e.target.checked ?
                    setSelectedProduct({...selectedProduct, offered_price: selectedProduct.price})
                    :
                    setSelectedProduct({...selectedProduct, offered_price: 0})
                  )}
                  style={{marginRight: 10}}
                />
                Enable Offered Price
              </label>
              {((selectedProduct?.offered_price) > 0 || activeField === 'offered_price') && <>
                <label>Offered Price</label>
                <Input
                  onFocus={()=>setActiveField('offered_price')}
                  onBlur={()=>setActiveField()}
                  value={selectedProduct ? (selectedProduct.offered_price) : ""}
                  onChange={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      offered_price: e.target.value,
                    })
                  }
                />
              </>}
            </Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label> Stock quantity </label>
                <Input
                  placeholder="Stock Quantity"
                  value={selectedProduct ? selectedProduct.stock_quantity : ""}
                  onChange={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      stock_quantity: e.target.value,
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label> Minimum Order quantity </label>
                <Input
                  value={
                    selectedProduct ? selectedProduct.min_order_quantity : ""
                  }
                  onChange={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      min_order_quantity: e.target.value,
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Checkbox
                label="Is Pinned"
                style={{ paddingTop: 5 }}
                checked={selectedProduct ? selectedProduct.is_pinned : ""}
                onChange={(e, data) =>
                  setSelectedProduct({
                    ...selectedProduct,
                    is_pinned: data.checked,
                  })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Dropdown
                placeholder="Select Category"
                selection
                options={categories_option}
                value={selectedProduct ? selectedProduct.category : ""}
                onChange={(e, { value }) =>
                  setSelectedProduct({ ...selectedProduct, category: value })
                }
              />
            </Form.Field>
            <Form.Field>
              <label>Image</label>
              <Input type="file" onChange={handleFileUpload} />
            </Form.Field>




            <Form.Field>
              <label>How To Use</label>
              <TextArea
                placeholder="How To Use"
                value={selectedProduct?.how_to_use || ""}
                onChange={(e) =>
                  setSelectedProduct({ ...selectedProduct, how_to_use: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Ingredients</label>
              <TextArea
                placeholder="Ingredients"
                value={selectedProduct?.ingredients || ""}
                onChange={(e) =>
                  setSelectedProduct({ ...selectedProduct, ingredients: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Clinical Tests</label>
              <TextArea
                placeholder="Clinical Tests"
                value={selectedProduct?.clinical_tests || ""}
                onChange={(e) =>
                  setSelectedProduct({ ...selectedProduct, clinical_tests: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>
            <Form.Field>
              <label>Product Details</label>
              <TextArea
                placeholder="Product Details"
                value={selectedProduct?.product_details || ""}
                onChange={(e) =>
                  setSelectedProduct({ ...selectedProduct, product_details: e.target.value })
                }
                style={{ minHeight: 100 }}
              />
            </Form.Field>




          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={handleEditProduct}
            positive
          />
        </Modal.Actions>
      </Modal>

      {/* View Modal */}
      <Modal
        size="small"
        open={viewModalOpen}
        onClose={() => {
          setViewModal(false);
          setSelectedProduct(null);
        }}
        closeIcon
      >
        <Modal.Header> View Product </Modal.Header>
        <Modal.Content>
          <ProductDetailComponent admin={true} productData={selectedProduct} />
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default ProductsPage;
