import Nav from '../components/nav'
import Footer from '../components/footer'

const Main = ({children, heroElement}) => {
  return(
    <>
      <Nav/>
        <div style={{minHeight: '60vh'}}>
        {heroElement}
        <div style={{marginTop: heroElement ? 0 : 100}}>
        {children}
        </div>
        </div>
      <Footer/>
    </>
  )
}

export default Main
