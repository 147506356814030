import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'account',
  initialState: { loading: false },
  reducers: {
    
    // USERPROFILE REDUCER FUNCTION

    // USERPROFILE FETCH LIST
    fetchAccountUserprofileListStart: stateLoadingStart,
    fetchAccountUserprofileListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_list`),
    fetchAccountUserprofileListFailure: stateError,

    // USERPROFILE FETCH ITEM
    fetchAccountUserprofileItemStart: stateLoadingStart,
    fetchAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    fetchAccountUserprofileItemFailure: stateError,

    // USERPROFILE CREATE ITEM
    createAccountUserprofileItemStart: stateLoadingStart,
    createAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    createAccountUserprofileItemFailure: stateError,

    // USERPROFILE UPDATE ITEM
    updateAccountUserprofileItemStart: stateLoadingStart,
    updateAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `userprofile_item`),
    updateAccountUserprofileItemFailure: stateError,

    // USERPROFILE DELETE ITEM
    deleteAccountUserprofileItemStart: stateLoadingStart,
    deleteAccountUserprofileItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_userprofile`),
    deleteAccountUserprofileItemFailure: stateError,


    // USERMESSAGEDISPATCH REDUCER FUNCTION

    // USERMESSAGEDISPATCH FETCH LIST
    fetchAccountUsermessagedispatchListStart: stateLoadingStart,
    fetchAccountUsermessagedispatchListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_list`),
    fetchAccountUsermessagedispatchListFailure: stateError,

    // USERMESSAGEDISPATCH FETCH ITEM
    fetchAccountUsermessagedispatchItemStart: stateLoadingStart,
    fetchAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    fetchAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH CREATE ITEM
    createAccountUsermessagedispatchItemStart: stateLoadingStart,
    createAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    createAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH UPDATE ITEM
    updateAccountUsermessagedispatchItemStart: stateLoadingStart,
    updateAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usermessagedispatch_item`),
    updateAccountUsermessagedispatchItemFailure: stateError,

    // USERMESSAGEDISPATCH DELETE ITEM
    deleteAccountUsermessagedispatchItemStart: stateLoadingStart,
    deleteAccountUsermessagedispatchItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_usermessagedispatch`),
    deleteAccountUsermessagedispatchItemFailure: stateError,


    // TENANTCHECKLIST REDUCER FUNCTION

    // TENANTCHECKLIST FETCH LIST
    fetchAccountTenantchecklistListStart: stateLoadingStart,
    fetchAccountTenantchecklistListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantchecklist_list`),
    fetchAccountTenantchecklistListFailure: stateError,

    // TENANTCHECKLIST FETCH ITEM
    fetchAccountTenantchecklistItemStart: stateLoadingStart,
    fetchAccountTenantchecklistItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantchecklist_item`),
    fetchAccountTenantchecklistItemFailure: stateError,

    // TENANTCHECKLIST CREATE ITEM
    createAccountTenantchecklistItemStart: stateLoadingStart,
    createAccountTenantchecklistItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantchecklist_item`),
    createAccountTenantchecklistItemFailure: stateError,

    // TENANTCHECKLIST UPDATE ITEM
    updateAccountTenantchecklistItemStart: stateLoadingStart,
    updateAccountTenantchecklistItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantchecklist_item`),
    updateAccountTenantchecklistItemFailure: stateError,

    // TENANTCHECKLIST DELETE ITEM
    deleteAccountTenantchecklistItemStart: stateLoadingStart,
    deleteAccountTenantchecklistItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenantchecklist`),
    deleteAccountTenantchecklistItemFailure: stateError,


    // TENANTPAYMENT REDUCER FUNCTION

    // TENANTPAYMENT FETCH LIST
    fetchAccountTenantpaymentListStart: stateLoadingStart,
    fetchAccountTenantpaymentListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantpayment_list`),
    fetchAccountTenantpaymentListFailure: stateError,

    // TENANTPAYMENT FETCH ITEM
    fetchAccountTenantpaymentItemStart: stateLoadingStart,
    fetchAccountTenantpaymentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantpayment_item`),
    fetchAccountTenantpaymentItemFailure: stateError,

    // TENANTPAYMENT CREATE ITEM
    createAccountTenantpaymentItemStart: stateLoadingStart,
    createAccountTenantpaymentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantpayment_item`),
    createAccountTenantpaymentItemFailure: stateError,

    // TENANTPAYMENT UPDATE ITEM
    updateAccountTenantpaymentItemStart: stateLoadingStart,
    updateAccountTenantpaymentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantpayment_item`),
    updateAccountTenantpaymentItemFailure: stateError,

    // TENANTPAYMENT DELETE ITEM
    deleteAccountTenantpaymentItemStart: stateLoadingStart,
    deleteAccountTenantpaymentItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenantpayment`),
    deleteAccountTenantpaymentItemFailure: stateError,


    // TENANTADMIN REDUCER FUNCTION

    // TENANTADMIN FETCH LIST
    fetchAccountTenantadminListStart: stateLoadingStart,
    fetchAccountTenantadminListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantadmin_list`),
    fetchAccountTenantadminListFailure: stateError,

    // TENANTADMIN FETCH ITEM
    fetchAccountTenantadminItemStart: stateLoadingStart,
    fetchAccountTenantadminItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantadmin_item`),
    fetchAccountTenantadminItemFailure: stateError,

    // TENANTADMIN CREATE ITEM
    createAccountTenantadminItemStart: stateLoadingStart,
    createAccountTenantadminItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantadmin_item`),
    createAccountTenantadminItemFailure: stateError,

    // TENANTADMIN UPDATE ITEM
    updateAccountTenantadminItemStart: stateLoadingStart,
    updateAccountTenantadminItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tenantadmin_item`),
    updateAccountTenantadminItemFailure: stateError,

    // TENANTADMIN DELETE ITEM
    deleteAccountTenantadminItemStart: stateLoadingStart,
    deleteAccountTenantadminItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tenantadmin`),
    deleteAccountTenantadminItemFailure: stateError,


  },
});

export const {
  fetchAccountUserprofileListStart, fetchAccountUsermessagedispatchListStart, fetchAccountTenantchecklistListStart, fetchAccountTenantpaymentListStart, fetchAccountTenantadminListStart,
  fetchAccountUserprofileListSuccess, fetchAccountUsermessagedispatchListSuccess, fetchAccountTenantchecklistListSuccess, fetchAccountTenantpaymentListSuccess, fetchAccountTenantadminListSuccess,
  fetchAccountUserprofileListFailure, fetchAccountUsermessagedispatchListFailure, fetchAccountTenantchecklistListFailure, fetchAccountTenantpaymentListFailure, fetchAccountTenantadminListFailure,
  fetchAccountUserprofileItemStart, fetchAccountUsermessagedispatchItemStart, fetchAccountTenantchecklistItemStart, fetchAccountTenantpaymentItemStart, fetchAccountTenantadminItemStart,
  fetchAccountUserprofileItemSuccess, fetchAccountUsermessagedispatchItemSuccess, fetchAccountTenantchecklistItemSuccess, fetchAccountTenantpaymentItemSuccess, fetchAccountTenantadminItemSuccess,
  fetchAccountUserprofileItemFailure, fetchAccountUsermessagedispatchItemFailure, fetchAccountTenantchecklistItemFailure, fetchAccountTenantpaymentItemFailure, fetchAccountTenantadminItemFailure,
  createAccountUserprofileItemStart, createAccountUsermessagedispatchItemStart, createAccountTenantchecklistItemStart, createAccountTenantpaymentItemStart, createAccountTenantadminItemStart,
  createAccountUserprofileItemSuccess, createAccountUsermessagedispatchItemSuccess, createAccountTenantchecklistItemSuccess, createAccountTenantpaymentItemSuccess, createAccountTenantadminItemSuccess,
  createAccountUserprofileItemFailure, createAccountUsermessagedispatchItemFailure, createAccountTenantchecklistItemFailure, createAccountTenantpaymentItemFailure, createAccountTenantadminItemFailure,
  updateAccountUserprofileItemStart, updateAccountUsermessagedispatchItemStart, updateAccountTenantchecklistItemStart, updateAccountTenantpaymentItemStart, updateAccountTenantadminItemStart,
  updateAccountUserprofileItemSuccess, updateAccountUsermessagedispatchItemSuccess, updateAccountTenantchecklistItemSuccess, updateAccountTenantpaymentItemSuccess, updateAccountTenantadminItemSuccess,
  updateAccountUserprofileItemFailure, updateAccountUsermessagedispatchItemFailure, updateAccountTenantchecklistItemFailure, updateAccountTenantpaymentItemFailure, updateAccountTenantadminItemFailure,
  deleteAccountUserprofileItemStart, deleteAccountUsermessagedispatchItemStart, deleteAccountTenantchecklistItemStart, deleteAccountTenantpaymentItemStart, deleteAccountTenantadminItemStart,
  deleteAccountUserprofileItemSuccess, deleteAccountUsermessagedispatchItemSuccess, deleteAccountTenantchecklistItemSuccess, deleteAccountTenantpaymentItemSuccess, deleteAccountTenantadminItemSuccess,
  deleteAccountUserprofileItemFailure, deleteAccountUsermessagedispatchItemFailure, deleteAccountTenantchecklistItemFailure, deleteAccountTenantpaymentItemFailure, deleteAccountTenantadminItemFailure
} = slice.actions;

export default slice.reducer;
