import { configureStore } from '@reduxjs/toolkit';
import accountReducer from '../features/account';
import chatReducer from '../features/chat';
import customerReducer from '../features/customer';
import deliveryReducer from '../features/delivery';
import feedbackReducer from '../features/feedback';
import notificationReducer from '../features/notification';
import orderReducer from '../features/order';
import packagingReducer from '../features/packaging';
import paymentReducer from '../features/payment';
import productReducer from '../features/product';
import storeReducer from '../features/store';
//import supplierReducer from '../features/supplier';
import pricingReducer from '../features/pricing';
import pluginReducer from '../features/plugin';
import tenantReducer from '../features/tenant';

export default configureStore({
  reducer: {
    account: accountReducer,
    chat: chatReducer,
    customer: customerReducer,
    delivery: deliveryReducer,
    feedback: feedbackReducer,
    notification: notificationReducer,
    order: orderReducer,
    packaging: packagingReducer,
    payment: paymentReducer,
    product: productReducer,
    store: storeReducer,
//    supplier: supplierReducer,
    pricing: pricingReducer,
    plugin: pluginReducer,
    tenant: tenantReducer,
  },
});
