import axios from '../utils/axios';

import {fetchProductCategoryListStart, fetchProductCategoryListSuccess, fetchProductCategoryListFailure} from '../features/product'
import {fetchProductCategoryItemStart, fetchProductCategoryItemSuccess, fetchProductCategoryItemFailure} from '../features/product'
import {createProductCategoryItemStart, createProductCategoryItemSuccess, createProductCategoryItemFailure} from '../features/product'
import {updateProductCategoryItemStart, updateProductCategoryItemSuccess, updateProductCategoryItemFailure} from '../features/product'
import {deleteProductCategoryItemStart, deleteProductCategoryItemSuccess, deleteProductCategoryItemFailure} from '../features/product'

import {fetchProductProductListStart, fetchProductProductListSuccess, fetchProductProductListFailure} from '../features/product'
import {fetchProductProductItemStart, fetchProductProductItemSuccess, fetchProductProductItemFailure} from '../features/product'
import {createProductProductItemStart, createProductProductItemSuccess, createProductProductItemFailure} from '../features/product'
import {updateProductProductItemStart, updateProductProductItemSuccess, updateProductProductItemFailure} from '../features/product'
import {deleteProductProductItemStart, deleteProductProductItemSuccess, deleteProductProductItemFailure} from '../features/product'

import {fetchProductHowtouseListStart, fetchProductHowtouseListSuccess, fetchProductHowtouseListFailure} from '../features/product'
import {fetchProductHowtouseItemStart, fetchProductHowtouseItemSuccess, fetchProductHowtouseItemFailure} from '../features/product'
import {createProductHowtouseItemStart, createProductHowtouseItemSuccess, createProductHowtouseItemFailure} from '../features/product'
import {updateProductHowtouseItemStart, updateProductHowtouseItemSuccess, updateProductHowtouseItemFailure} from '../features/product'
import {deleteProductHowtouseItemStart, deleteProductHowtouseItemSuccess, deleteProductHowtouseItemFailure} from '../features/product'

import {fetchProductIngredientListStart, fetchProductIngredientListSuccess, fetchProductIngredientListFailure} from '../features/product'
import {fetchProductIngredientItemStart, fetchProductIngredientItemSuccess, fetchProductIngredientItemFailure} from '../features/product'
import {createProductIngredientItemStart, createProductIngredientItemSuccess, createProductIngredientItemFailure} from '../features/product'
import {updateProductIngredientItemStart, updateProductIngredientItemSuccess, updateProductIngredientItemFailure} from '../features/product'
import {deleteProductIngredientItemStart, deleteProductIngredientItemSuccess, deleteProductIngredientItemFailure} from '../features/product'

import {fetchProductClinicaltestListStart, fetchProductClinicaltestListSuccess, fetchProductClinicaltestListFailure} from '../features/product'
import {fetchProductClinicaltestItemStart, fetchProductClinicaltestItemSuccess, fetchProductClinicaltestItemFailure} from '../features/product'
import {createProductClinicaltestItemStart, createProductClinicaltestItemSuccess, createProductClinicaltestItemFailure} from '../features/product'
import {updateProductClinicaltestItemStart, updateProductClinicaltestItemSuccess, updateProductClinicaltestItemFailure} from '../features/product'
import {deleteProductClinicaltestItemStart, deleteProductClinicaltestItemSuccess, deleteProductClinicaltestItemFailure} from '../features/product'

import {fetchProductProductdetailListStart, fetchProductProductdetailListSuccess, fetchProductProductdetailListFailure} from '../features/product'
import {fetchProductProductdetailItemStart, fetchProductProductdetailItemSuccess, fetchProductProductdetailItemFailure} from '../features/product'
import {createProductProductdetailItemStart, createProductProductdetailItemSuccess, createProductProductdetailItemFailure} from '../features/product'
import {updateProductProductdetailItemStart, updateProductProductdetailItemSuccess, updateProductProductdetailItemFailure} from '../features/product'
import {deleteProductProductdetailItemStart, deleteProductProductdetailItemSuccess, deleteProductProductdetailItemFailure} from '../features/product'


// CATEGORY ACTIONS
export const fetchProductCategoryList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductCategoryListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/category/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductCategoryListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductCategoryListFailure({key, error: error.message}));
    }
}
export const fetchProductCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductCategoryItemFailure({key, error: error.message}));
    }
}
export const createProductCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/category/`, info.payload, { headers: info.headers });
        dispatch(createProductCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductCategoryItemFailure({key, error: error.message}));
    }
}
export const updateProductCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductCategoryItemFailure({key, error: error.message}));
    }
}
export const deleteProductCategoryItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductCategoryItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/category/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductCategoryItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductCategoryItemFailure({key, error: error.message}));
    }
}


// PRODUCT ACTIONS
export const fetchProductProductList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductProductListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/product/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductProductListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductProductListFailure({key, error: error.message}));
    }
}
export const fetchProductProductItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductProductItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/product/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductProductItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductProductItemFailure({key, error: error.message}));
    }
}
export const createProductProductItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductProductItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/product/`, info.payload, { headers: info.headers });
        dispatch(createProductProductItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductProductItemFailure({key, error: error.message}));
    }
}
export const updateProductProductItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductProductItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/product/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductProductItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductProductItemFailure({key, error: error.message}));
    }
}
export const deleteProductProductItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductProductItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/product/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductProductItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductProductItemFailure({key, error: error.message}));
    }
}


// HOWTOUSE ACTIONS
export const fetchProductHowtouseList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductHowtouseListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/howtouse/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductHowtouseListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductHowtouseListFailure({key, error: error.message}));
    }
}
export const fetchProductHowtouseItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductHowtouseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/howtouse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductHowtouseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductHowtouseItemFailure({key, error: error.message}));
    }
}
export const createProductHowtouseItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductHowtouseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/howtouse/`, info.payload, { headers: info.headers });
        dispatch(createProductHowtouseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductHowtouseItemFailure({key, error: error.message}));
    }
}
export const updateProductHowtouseItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductHowtouseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/howtouse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductHowtouseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductHowtouseItemFailure({key, error: error.message}));
    }
}
export const deleteProductHowtouseItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductHowtouseItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/howtouse/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductHowtouseItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductHowtouseItemFailure({key, error: error.message}));
    }
}


// INGREDIENT ACTIONS
export const fetchProductIngredientList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductIngredientListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/ingredient/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductIngredientListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductIngredientListFailure({key, error: error.message}));
    }
}
export const fetchProductIngredientItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductIngredientItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/ingredient/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductIngredientItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductIngredientItemFailure({key, error: error.message}));
    }
}
export const createProductIngredientItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductIngredientItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/ingredient/`, info.payload, { headers: info.headers });
        dispatch(createProductIngredientItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductIngredientItemFailure({key, error: error.message}));
    }
}
export const updateProductIngredientItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductIngredientItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/ingredient/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductIngredientItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductIngredientItemFailure({key, error: error.message}));
    }
}
export const deleteProductIngredientItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductIngredientItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/ingredient/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductIngredientItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductIngredientItemFailure({key, error: error.message}));
    }
}


// CLINICALTEST ACTIONS
export const fetchProductClinicaltestList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductClinicaltestListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/clinicaltest/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductClinicaltestListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductClinicaltestListFailure({key, error: error.message}));
    }
}
export const fetchProductClinicaltestItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductClinicaltestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/clinicaltest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductClinicaltestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductClinicaltestItemFailure({key, error: error.message}));
    }
}
export const createProductClinicaltestItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductClinicaltestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/clinicaltest/`, info.payload, { headers: info.headers });
        dispatch(createProductClinicaltestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductClinicaltestItemFailure({key, error: error.message}));
    }
}
export const updateProductClinicaltestItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductClinicaltestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/clinicaltest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductClinicaltestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductClinicaltestItemFailure({key, error: error.message}));
    }
}
export const deleteProductClinicaltestItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductClinicaltestItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/clinicaltest/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductClinicaltestItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductClinicaltestItemFailure({key, error: error.message}));
    }
}


// PRODUCTDETAIL ACTIONS
export const fetchProductProductdetailList = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductProductdetailListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/productdetail/`, { params: info.params, headers: info.headers });
        dispatch(fetchProductProductdetailListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductProductdetailListFailure({key, error: error.message}));
    }
}
export const fetchProductProductdetailItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchProductProductdetailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/product/productdetail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchProductProductdetailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchProductProductdetailItemFailure({key, error: error.message}));
    }
}
export const createProductProductdetailItem = async (dispatch, info, key) => {
    try{
        dispatch(createProductProductdetailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/product/productdetail/`, info.payload, { headers: info.headers });
        dispatch(createProductProductdetailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createProductProductdetailItemFailure({key, error: error.message}));
    }
}
export const updateProductProductdetailItem = async (dispatch, info, key) => {
    try{
        dispatch(updateProductProductdetailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/product/productdetail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateProductProductdetailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateProductProductdetailItemFailure({key, error: error.message}));
    }
}
export const deleteProductProductdetailItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteProductProductdetailItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/product/productdetail/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteProductProductdetailItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteProductProductdetailItemFailure({key, error: error.message}));
    }
}




