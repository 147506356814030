import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Message,
  Modal,
  Radio,
  Segment,
  Table,
} from "semantic-ui-react";
import QR from "assets/QR.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { configureAccountUserprofile } from "services/access/account";
import { utils } from "services/api-data/src/App";
import {
  configureOrderOrder,
  configureOrderOrderitem,
} from "services/access/order";
import { configureDeliveryDeliveryorder } from "services/access/delivery";
import { toast } from "react-toastify";
import { API_BASE_URL, SITE_URL } from "App";
import { Link, useNavigate } from "react-router-dom";
import { configurePaymentPaymentmethod } from "services/access/payment";

export default function CheckoutForm({ is_cart, product_data }) {
  const user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    city: "",
    address: "",
    postalCode: "",
    country: "",
    state: "",
  });
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState();
  const [fullName, setFullName] = useState();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureAccountUserprofile(
    utils,
    dispatch,
    state
  );
  const orderConfig = configureOrderOrder(utils, dispatch, state);
  const orderItemConfig = configureOrderOrderitem(utils, dispatch, state);
  const delieveryConfig = configureDeliveryDeliveryorder(
    utils,
    dispatch,
    state
  );
  const paymentConfig = configurePaymentPaymentmethod(utils, dispatch, state);

  useEffect(() => {
    if (user_id) {
      action.fetchUserprofileItem(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    paymentConfig.action.fetchPaymentmethodList();
  }, []);

  useEffect(() => {
    if (data?.userprofile_fetch_item) {
      const fetchedProfile = data.userprofile_fetch_item;
      setProfileData(fetchedProfile);
      setPhoneNumber(fetchedProfile?.contact_number);

      const profileAddress = {
        city: fetchedProfile.city,
        address: fetchedProfile.address,
        postalCode: fetchedProfile.postal_code,
        country: fetchedProfile.country,
        state: fetchedProfile.state,
      };
      setAddresses([profileAddress]);
      setSelectedAddress(profileAddress);
      if (fetchedProfile?.contact_number === "") {
        setOpenModal(true);
      }
    } else {
      setOpenModal(true);
    }
  }, [data?.userprofile_fetch_item]);

  // useEffect(() => {
  //   if (phoneNumber === "") {
  //     setOpenModal(true);
  //   }
  // }, [phoneNumber]);

  useEffect(() => {
    if (paymentConfig.data.paymentmethod_fetch_list) {
      setPaymentMethod(paymentConfig.data.paymentmethod_fetch_list);
    }
  }, [paymentConfig.data.paymentmethod_fetch_list]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const calculateTotalAmount = () => {
    if (is_cart) {
      return product_data
        .map((c) => ((c.offered_price > 0) ? c.offered_price : c.price) * c.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    } else {
      return ((product_data.offered_price > 0) ? product_data.offered_price : product_data.price) * product_data.quantity;
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      const total_amount = calculateTotalAmount();
      const orderResponse = await orderConfig.action?.createOrderItem({
        total_amount: total_amount,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleOrderCreation = async () => {
      const order_id = orderConfig?.data?.order_create_item?.id;
      if (!order_id) {
        toast.error("Order isn't created");
        return;
      }

      try {
        const orderItemsPromises = (
          is_cart ? product_data : [product_data]
        ).map((product) =>
          orderItemConfig.action.createOrderitemItem({
            order: order_id,
            product: is_cart ? product.product : product.id,
            quantity: product.quantity,
            unit_price: ((product.offered_price > 0) ? product.offered_price : product.price),
          })
        );
        await Promise.all(orderItemsPromises);

        const deliveryOrder =
          await delieveryConfig.action.createDeliveryorderItem({
            order: order_id,
            address: selectedAddress.address,
            city: selectedAddress.city,
            state: selectedAddress.state,
            postal_code: selectedAddress.postalCode,
            country: selectedAddress.country,
            contact_number: phoneNumber,
            secondary_contact_number: secondaryPhoneNumber,
            full_name: fullName
          });
        navigate(
          `/checkout/checkout-success/${order_id}?payment_method=${paymentMethodId}`
        );
        setDataLoading(false);
      } catch (error) {
        console.error("Error during order creation:", error);
      }
    };

    if (orderConfig?.data?.order_create_item?.id) {
      handleOrderCreation();
      setDataLoading(true);
    }
  }, [orderConfig?.data?.order_create_item?.id]);

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleAddNewAddress = () => {
    setAddresses([...addresses, newAddress]);
    setSelectedAddress(newAddress);
    setNewAddress({
      city: "",
      address: "",
      postalCode: "",
      country: "",
      state: "",
    });
    setShowNewAddressForm(false);
  };

  const formatAddress = (address) => {
    const { address: addr, city, state, postalCode, country } = address;
    const parts = [addr, city, state, postalCode, country].filter(
      (part) => part && part.trim() !== ""
    );
    return parts.join(", ");
  };

  const currency = "Rs.";

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
                <Container>
                  <Form>
                    <Form.Field>
                      <label>Full Name:</label>
                      <Input
                        type="text"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      <label>Phone number:</label>
                      <Input
                        type="tel"
                        placeholder="Phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <label>Secondary Phone number:</label>
                      <Input
                        type="tel"
                        placeholder="Phone number"
                        value={secondaryPhoneNumber}
                        onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                      />
                    </Form.Field>
                  </Form>
                </Container>
            </Grid.Row>
            <br/>
            <Grid.Row>
                <Form>
                  <Form.Group widths={"equal"}>
                    <Form.Input
                      label="Detail Address:"
                      name="address"
                      value={newAddress.address}
                      onChange={handleNewAddressChange}
                    />

                  </Form.Group>
                  <Form.Group widths={"equal"}>
                    <Form.Input
                      label="District:"
                      name="city"
                      value={newAddress.city}
                      onChange={handleNewAddressChange}
                    />
                    <Form.Input
                      label="State:"
                      name="state"
                      value={newAddress.state}
                      onChange={handleNewAddressChange}
                    />
                  </Form.Group>
                </Form>
            </Grid.Row>
            <Segment>
            <Header as={"h3"}> Total Price: {currency} {calculateTotalAmount()} </Header>
              <Grid columns={"equal"} doubling stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as={"h4"}> Select Payment Method:<div>
                        <Button
                          basic={selectedPaymentMethod !== 'COD'}
                          circular
                          style={{margin: 5}}
                          color="black"
                          onClick={() => {
                            setSelectedPaymentMethod('COD');
                          }}
                        >
                          Cash On Delivery
                        </Button>
                        <Button
                          basic={selectedPaymentMethod !== 'FONEPAYQR'}
                          circular
                          style={{margin: 5}}
                          color="black"
                          onClick={() => {
                            setSelectedPaymentMethod('FONEPAYQR');
                          }}
                        >
                          FonePay QR
                        </Button>
                     </div></Header>
                  </Grid.Column>
                </Grid.Row>
                {selectedPaymentMethod === "COD" && (
                    <div style={{marginBottom: 10}}>
                      <Message info>
                        <p>You have selected Cash on Delivery.</p>
                      </Message>{" "}
                    </div>
                )}
                {selectedPaymentMethod === "FONEPAYQR" && (
                  <Grid.Row>
                    <Grid.Column>
                      <Image src={QR} />
                      <Message info>
                        <p>
                          {" "}
                          Send us your payment success screenshot or receipt to our whatsapp number
                          <b> 9802379096 </b>{" "}
                        </p>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Button
        icon
        floated="right"
        positive
        onClick={() => handleSubmit()}
        style={{ margin: '10px 0px' }}
        // loading={isLoading || dataLoading}
        disabled={!selectedPaymentMethod}
      >
        {" "}
        Place My Order <Icon name="arrow right" />
      </Button>
    </>
  );
}
