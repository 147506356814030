import React from "react";
import {
  Container,
  Segment,
  Grid,
  Header,
  List,
  Icon,
} from "semantic-ui-react";

const Footer = ({ style }) => {
  return (
    <Segment inverted vertical style={{ padding: "5em 0em", ...style }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="About" />
              <List link inverted>
                <List.Item as="a" href="/about-us">
                  Company
                </List.Item>
                <List.Item as="a" href="/helpcenter">
                  Help Center
                </List.Item>
                <List.Item as="a" href="/contact-us">
                  Contact Us
                </List.Item>
                <List.Item as="a" href="/privacy-policy">
                  Privacy Policy
                </List.Item>
                <List.Item as="a" href="/terms-and-conditions">
                  Terms and Conditions
                </List.Item>
                <List.Item as="a" href="/trade">
                  Trade Assurance
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="Services" />
              <List link inverted>
                <List.Item as="a" href="/brands">
                  Search Products
                </List.Item>
                <List.Item as="a" href="/admin">
                  Order Tracking
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="Follow Us" />
              <List link inverted>
                <List.Item>
                  <span>
                    <Icon name="facebook" />
                  </span>
                  <span>
                    <a target="_blank" href="https://www.facebook.com/glossmingle">Facebook</a>
                  </span>
                </List.Item>
                <List.Item>
                  <span>
                    <Icon name="instagram" />
                  </span>
                  <span>
                    <a target="_blank" href="https://www.instagram.com/glossmingle_official/">Instagram</a>
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="Contact Us" />
              <p>We always love to hear from our customers.</p>
              <List link inverted>
                <List.Item as="a">Email: info@glossmingle.com</List.Item>
                <List.Item as="a">Phone: 977-1-9802379096</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <p
          style={{
            textAlign: "center",
            marginTop: 20,
            paddingTop: 20,
            borderTop: "1px solid #444",
          }}
        >
          © 2024 Gloss Minlge. All rights reserved.
        </p>
      </Container>
    </Segment>
  );
};

export default Footer;
