import axios from '../utils/axios';

import {fetchTenantTenantListStart, fetchTenantTenantListSuccess, fetchTenantTenantListFailure} from '../features/tenant'
import {fetchTenantTenantItemStart, fetchTenantTenantItemSuccess, fetchTenantTenantItemFailure} from '../features/tenant'
import {createTenantTenantItemStart, createTenantTenantItemSuccess, createTenantTenantItemFailure} from '../features/tenant'
import {updateTenantTenantItemStart, updateTenantTenantItemSuccess, updateTenantTenantItemFailure} from '../features/tenant'
import {deleteTenantTenantItemStart, deleteTenantTenantItemSuccess, deleteTenantTenantItemFailure} from '../features/tenant'

import {fetchTenantTenantanalyticsListStart, fetchTenantTenantanalyticsListSuccess, fetchTenantTenantanalyticsListFailure} from '../features/tenant'
import {fetchTenantTenantanalyticsItemStart, fetchTenantTenantanalyticsItemSuccess, fetchTenantTenantanalyticsItemFailure} from '../features/tenant'
import {createTenantTenantanalyticsItemStart, createTenantTenantanalyticsItemSuccess, createTenantTenantanalyticsItemFailure} from '../features/tenant'
import {updateTenantTenantanalyticsItemStart, updateTenantTenantanalyticsItemSuccess, updateTenantTenantanalyticsItemFailure} from '../features/tenant'
import {deleteTenantTenantanalyticsItemStart, deleteTenantTenantanalyticsItemSuccess, deleteTenantTenantanalyticsItemFailure} from '../features/tenant'


// TENANT ACTIONS
export const fetchTenantTenantList = async (dispatch, info, key) => {
    try{
        dispatch(fetchTenantTenantListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/tenant/tenant/`, { params: info.params, headers: info.headers });
        dispatch(fetchTenantTenantListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchTenantTenantListFailure({key, error: error.message}));
    }
}
export const fetchTenantTenantItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchTenantTenantItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/tenant/tenant/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchTenantTenantItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchTenantTenantItemFailure({key, error: error.message}));
    }
}
export const createTenantTenantItem = async (dispatch, info, key) => {
    try{
        dispatch(createTenantTenantItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/tenant/tenant/`, info.payload, { headers: info.headers });
        dispatch(createTenantTenantItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createTenantTenantItemFailure({key, error: error.message}));
    }
}
export const updateTenantTenantItem = async (dispatch, info, key) => {
    try{
        dispatch(updateTenantTenantItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/tenant/tenant/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateTenantTenantItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateTenantTenantItemFailure({key, error: error.message}));
    }
}
export const deleteTenantTenantItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteTenantTenantItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/tenant/tenant/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteTenantTenantItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteTenantTenantItemFailure({key, error: error.message}));
    }
}


// TENANTANALYTICS ACTIONS
export const fetchTenantTenantanalyticsList = async (dispatch, info, key) => {
    try{
        dispatch(fetchTenantTenantanalyticsListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/tenant/tenantanalytics/`, { params: info.params, headers: info.headers });
        dispatch(fetchTenantTenantanalyticsListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchTenantTenantanalyticsListFailure({key, error: error.message}));
    }
}
export const fetchTenantTenantanalyticsItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchTenantTenantanalyticsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/tenant/tenantanalytics/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchTenantTenantanalyticsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchTenantTenantanalyticsItemFailure({key, error: error.message}));
    }
}
export const createTenantTenantanalyticsItem = async (dispatch, info, key) => {
    try{
        dispatch(createTenantTenantanalyticsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/tenant/tenantanalytics/`, info.payload, { headers: info.headers });
        dispatch(createTenantTenantanalyticsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createTenantTenantanalyticsItemFailure({key, error: error.message}));
    }
}
export const updateTenantTenantanalyticsItem = async (dispatch, info, key) => {
    try{
        dispatch(updateTenantTenantanalyticsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/tenant/tenantanalytics/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateTenantTenantanalyticsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateTenantTenantanalyticsItemFailure({key, error: error.message}));
    }
}
export const deleteTenantTenantanalyticsItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteTenantTenantanalyticsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/tenant/tenantanalytics/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteTenantTenantanalyticsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteTenantTenantanalyticsItemFailure({key, error: error.message}));
    }
}




